import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwkursus: {
      id: '',
      sapk: {
        id: ''
      },
      jenisKursus: {
        id: null,
        nama: null,
        sertifikat: ''
      },
      namaKursus: '',
      noSertipikat: '',
      tanggalKursus: '',
      tahunKursus: '',
      institusiPenyelenggara: '',
      tempat: '',
      pelaksanaan: {
        awal: '',
        akhir: '',
        jp: ''
      },
      userId: null,
      NCSISTIME: null
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwkursus = value
    },
  }
})
