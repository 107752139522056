<template>
  <div>
    <v-card flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>Riwayat Kursus</v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="mr-3 mt-1">
          <rwkursusModalAdd />
        </div>

        <div style="max-width: 300px;">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <v-tab href="#tab-2" @click="getSAPK()">
              <h5>SIASN</h5>
            </v-tab>
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwkursusSIMASN />
        </v-tab-item>
        <v-tab-item value="tab-2" class="pa-5">
          <rwkursusSAPK />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import getData from '@/store/asn/rwkursus/tabs'
import rwkursusSIMASN from '@/components/asn/profil/rwkursus/rwkursus'
import rwkursusSAPK from '@/components/asn/profil/rwkursus/SAPK_rwkursus_Modal'
import rwkursusModalAdd from '@/components/asn/profil/rwkursus/ModalAdd'

export default {
  name: 'DataUtama',

  components: {
    rwkursusSIMASN,
    rwkursusSAPK,
    rwkursusModalAdd
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK
      },

      set(value) {
        getData.commit('getSAPK', value)
      }
    }
  },

  data: () => ({
    tab: null
  }),

  methods: {
    getSAPK() {
      getData.commit('getSAPK', true)
      getData.commit('getSIMASN', false)
    },
    getSIMASN() {
      getData.commit('getSIMASN', true)
      getData.commit('getSAPK', false)
    }
  }
}
</script>
