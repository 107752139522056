import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ModalToSIMASN: false,
        dataUtama: {
            id: '',
            SAPK: {
                id: ''
            },
            nipBaru: '',
            nipLama: ''
        },
        rwkursus: {
            id: '',
            sapk: {
                id: ''
            },
            jenisKursus: {
                id: null,
                nama: null,
                sertifikat: ''
            },
            namaKursus: '',
            noSertipikat: '',
            tanggalKursus: '',
            tahunKursus: '',
            institusiPenyelenggara: '',
            tempat: '',
            pelaksanaan: {
                awal: '',
                akhir: '',
                jp: ''
            },
            userId: null,
            NCSISTIME: null
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.ModalToSIMASN = value
        },
        tosimasnModal(state, value) {
            state.rwkursus = value
        },
        Datautama(state, value) {
            state.dataUtama = value
        }
    }
})
