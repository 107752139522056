<template>
  <v-dialog v-model="ModalToSIMASN" persistent max-width="40%">
    <v-card>
      <v-card-title class="headline"> Sinkron Data Ke IDASN? </v-card-title>
      <v-card-text>
        Data penghargaan yang anda pilih akan disalin ke data penghargaan di
        IDASN!
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" small depressed @click="ModalToSIMASN = false">
          Batal
        </v-btn>
        <v-btn
          color="primary"
          small
          depressed
          :loading="lbtn"
          :disabled="lbtn"
          @click="toSIMASN()"
        >
          Ya
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import modal_tosimasnState from "@/store/asn/rwkursus/modal_to_simasn";
import refreshView from "@/store/asn/rwkursus/viewRwkursus";

export default {
  data: () => ({
    token: Cookie.get("token"),
    lbtn: false,
  }),

  computed: {
    ModalToSIMASN: {
      get() {
        return modal_tosimasnState.state.ModalToSIMASN;
      },

      set(value) {
        modal_tosimasnState.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modal_tosimasnState.state.rwkursus;
      },
    },

    editedDatautama: {
      get() {
        return modal_tosimasnState.state.dataUtama;
      },
    },
  },

  methods: {
    async toSIMASN() {
      this.lbtn = true;

      let data = {
        id: this.item.id,
        jenisKursus: {
          id: this.item.jenisKursus.id,
          sertifikat: this.item.jenisKursus.sertifikat,
        },
        namaKursus: this.item.namaKursus,
        noSertipikat: this.item.noSertipikat,
        tanggalKursus: this.item.tanggalKursus,
        tahunKursus: this.item.tahunKursus,
        institusiPenyelenggara: this.item.institusiPenyelenggara,
        tempat: this.item.tempat,
        pelaksanaan: {
          awal: this.item.pelaksanaan.awal,
          akhir: this.item.pelaksanaan.akhir,
          jp: this.item.pelaksanaan.jp,
        },
      };

      const url =
        process.env.VUE_APP_ASN + "kursus/nip/" + this.$route.params.id;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            this.lbtn = false;
            console.log(error.response.status);
          } else {
            this.lbtn = false;
            console.log("Error", error.message);
          }
        });
    },

    closeModal() {
      this.ModalToSIMASN = false;
    },
  },
};
</script>
